import { CustomIcon } from "components/icon/customIcon.component";
import React from "react";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Row } from "./pagination.styled";
import { Color } from "config/colors";

type PaginationProps = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  totalPages: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  setPage,
  page,
  totalPages,
}) => {
  const onePage = page == totalPages;
  const haveNext = page + 1 !== totalPages && page !== totalPages;
  const havePrev = page !== 0;

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <Row>
      <CustomIcon
        onClick={() => {
          if (havePrev) {
            handlePrevious();
          }
        }}
        color={onePage ? Color.Disabled : Color.PitchBlack}
        name="paginationArrowLeft"
        size="20px"
        style={{ cursor: havePrev ? "pointer" : "auto" }}
      />
      <Text
        align="center"
        fontSize={FontSize.H4}
        fontFamily={FontFamily.MontserratRegular}
        color={page == totalPages ? Color.Disabled : Color.PitchBlack}
        style={{ cursor: "none" }}
      >
        {page}
      </Text>
      <CustomIcon
        onClick={() => {
          if (haveNext) {
            handleNext();
          }
        }}
        name="paginationArrowRight"
        color={onePage ? Color.Disabled : Color.PitchBlack}
        size="20px"
        style={{ cursor: haveNext ? "pointer" : "auto" }}
      />
    </Row>
  );
};
