import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto, GigStatus } from "model/Gig";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { Api } from "services/api/api.service";
import { TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";
import { CloseGigModal } from "../gig/closeGigModal.component";
import Modal from "@material-ui/core/Modal";
import { H3 } from "components/Typography/text.styled";
import { ModalBody } from "./gigDetail.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

export const GigDetailButtons: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [cloneGig, setCloneGig] = useState(false);

  return (
    <>
      <IconsContainer style={{ margin: "0 auto", marginTop: 40 }}>
        <IconCircularContainer
          isAccept
          isBig
          onClick={() => {
            setShowCreateGigModal(true);
          }}
        >
          <CustomIcon
            className="icon"
            name="pen"
            size="20px"
            padding="2px"
            color={Color.White}
          />
        </IconCircularContainer>
        <IconCircularContainer
          isBig
          backgroundColor={Color.MidnightBlue}
          onClick={() => {
            setCloneGig(true);
            setShowCreateGigModal(true);
          }}
        >
          <CustomIcon
            className="icon"
            name="clone"
            size="20px"
            padding="2px"
            color={Color.White}
          />
        </IconCircularContainer>
        <IconCircularContainer
          isBig
          onClick={() => {
            if (companyState.company?.id) {
              Api()
                .company.timeReport.getGigTimeReports(
                  companyState.company?.id,
                  gigData.id
                )
                .then((res) => {
                  if (
                    res.data.find(
                      (timeReport) => timeReport.status !== TimeReportStatus.NEW
                    )
                  ) {
                    alertDispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        icon: "alert",
                        title: "",
                        message: t(
                          "GigsCompany.ThisGigHasConnectedTimeReports"
                        ),
                      },
                    });
                  } else {
                    setDeleteModalOpen(true);
                  }
                });
            }
          }}
        >
          <CustomIcon
            className="icon"
            name="trashcan"
            size="20px"
            padding="2px"
            color={Color.White}
            disabled={gigData.status === GigStatus.CLOSED}
          />
        </IconCircularContainer>
      </IconsContainer>
      <CloseGigModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        gigData={gigData}
        onGigClosed={onGigClosed}
      />
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>
            {cloneGig ? t("GigsCompany.CloneGig") : t("GigsCompany.EditGig")}
          </H3>
          <SaveGigForm
            setShowCreateGigModal={setShowCreateGigModal}
            gigData={gigData}
            isClone={cloneGig}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
