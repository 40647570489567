import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto, GigCosts } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { getCosts } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { SalaryContainer, DropDownRow, Row } from "./gigDetail.styled";

type Props = {
  gigData: CompanyGigDto;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const GigDetailCosts: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [gigMinCost, setGigMinCost] = useState<GigCosts>(emptyCosts);
  const [gigMaxCost, setGigMaxCost] = useState<GigCosts>(emptyCosts);
  const [costsOpen, setCostsOpen] = useState(false);

  useEffect(() => {
    if (companyState.company?.id) {
      getCosts(
        gigData.shifts,
        gigData.minRate,
        gigData.maxRate,
        gigData.type,
        companyState.company.id,
        gigData.paymentType
      ).then((res) => {
        if (res) {
          setGigMinCost(res.minCost);
          setGigMaxCost(res.maxCost);
        }
      });
    }
  }, [companyState.company?.id, gigData]);

  return (
    <>
      <DropDownRow
        onClick={() => {
          setCostsOpen(!costsOpen);
        }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {t("GigsCompany.Costs")}
        </Text>
        <CustomIcon
          name={costsOpen ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </DropDownRow>
      {costsOpen && (
        <SalaryContainer>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.HourlyRate")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {t("FindWorker.HourlyRateSpanWithCurrency", {
                minRate: gigData.minRate,
                maxRate: gigData.maxRate,
              })}
            </Text>
          </Row>
          {gigMinCost && gigMaxCost && (
            <>
              <Row>
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratRegular}
                >
                  {gigMinCost && gigMinCost?.obAmount > 0
                    ? t("FindWorker.TotalSalaryWithOb")
                    : t("FindWorker.TotalSalary")}
                </Text>
                <Text fontSize={FontSize.Standard}>
                  {`${(gigMinCost?.grossSalary + gigMinCost.obAmount).toFixed(
                    2
                  )} - ${(
                    gigMaxCost?.grossSalary + gigMaxCost.obAmount
                  ).toFixed(2)} ${t("ProfileCompany.Kr")}`}
                </Text>
              </Row>
              <Row>
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratRegular}
                >
                  {t("FindWorker.TotalCost")}
                </Text>
                <Text fontSize={FontSize.Standard}>
                  {`${gigMinCost?.totalCost.toFixed(
                    2
                  )} - ${gigMaxCost?.totalCost.toFixed(2)} ${t(
                    "ProfileCompany.Kr"
                  )}`}
                </Text>
              </Row>
              <Row>
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratRegular}
                >
                  {t("GigsCompany.PaymentType")}
                </Text>
                <Text fontSize={FontSize.Standard}>
                  {gigData.paymentType == "None"
                    ? t("GigsCompany.NoPaymentType")
                    : t("GigsCompany.SimflexityPay")}
                </Text>
              </Row>
            </>
          )}
        </SalaryContainer>
      )}
    </>
  );
};
